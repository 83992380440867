import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, MenuItem, Select, FormControl, InputLabel
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const formatDollar = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    borderRadius: '15px',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#093d61',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const OverUnderSummaryChart = ({ budgetData, isModal, selectedSnapshots, selectedProject }) => {
  const classes = useStyles();

  // Initialize state Hooks at the top level
  const [firstSnapshot, setFirstSnapshot] = useState('');
  const [secondSnapshot, setSecondSnapshot] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedSnapshots && selectedSnapshots.length > 0) {
      setLoading(false);
      if (!firstSnapshot) {
        // Automatically set to first selected snapshot
        setFirstSnapshot(selectedSnapshots[0]);
      }
    }
  }, [selectedSnapshots]);

  // if (!selectedProject) {
  //   return (
  //     <Typography style={{ textAlign: 'center' }}>
  //       --
  //     </Typography>
  //   );
  // }
  
  if (selectedProject && (!budgetData || budgetData?.bvt_cost_code_mapping_available === false)) {
    return (
      <Typography style={{ textAlign: 'center', color: 'red', marginLeft: '25px', marginRight: '25px', marginTop: '10px', marginBottom: '10px' }}>
        Cost Code mapping is not available for this project. Please upgrade your BVT workbook to the latest version in order to view this data.
      </Typography>
    );
  }

  const rows = budgetData?.over_under_summary || { top_10: [], bottom_10: [] };
  const combinedRows = [...(rows.top_10 || []), ...(rows.bottom_10 || [])];

  // Top 10's for when modal is open
  const topPickups = rows.top_10 || [];
  const topLosses = rows.bottom_10 || [];

  // If still loading, display a loading message - can probs delete
  if (loading) {
    return <Typography>Loading snapshots...</Typography>;
  }

  // Filtered snapshots for second dropdown based on first snapshot - ensuring it's in the future
  const availableSnapshots = selectedSnapshots.filter((snapshot) => {
    return firstSnapshot ? new Date(snapshot.created_at) > new Date(firstSnapshot.created_at) : true;
  });

  const handleFirstSnapshotChange = (event) => {
    setFirstSnapshot(event.target.value);
    // Reset 2nd snapshot whenever the 1st changes
    setSecondSnapshot('');
  };

  const handleSecondSnapshotChange = (event) => {
    setSecondSnapshot(event.target.value);
  };

  return (
    <CardContent>
      {(!budgetData ||
        !budgetData.over_under_summary ||
        (combinedRows.length === 0 && isModal === false) ||
        (topPickups.length === 0 && topLosses.length === 0 && isModal === true)
      ) ? (
        <Typography style={{ textAlign: 'center' }} className="key-project-info-body">--</Typography>
      ) : isModal === false ? (
        // Case #1 - Full table shown in component
        combinedRows.length === 0 ? (
          <Typography style={{ textAlign: 'center' }} className="key-project-info-body">
            No Budget Information Found for this Project.
          </Typography>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
            <Table className={classes.table} aria-label="over-under summary table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combinedRows.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {formatDollar(row.value)}
                    </StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : (
        // Case #2 - Modal is opened and table is split into top-10 lists
        <>
          <p style={{ color: 'red' }}>NOTE: This feature is in development and will be released soon.</p>
          <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
            <FormControl style={{ flex: 1,  marginBottom: '20px' }}>
              <InputLabel>First Snapshot</InputLabel>
              <Select value={firstSnapshot} onChange={handleFirstSnapshotChange}>
                {selectedSnapshots.map((snapshot) => (
                  <MenuItem key={snapshot.id} value={snapshot}>
                    {snapshot.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ flex: 1, marginBottom: '20px' }} disabled={!firstSnapshot}>
              <InputLabel>Second Snapshot</InputLabel>
              <Select value={secondSnapshot} onChange={handleSecondSnapshotChange}>
                {availableSnapshots.map((snapshot) => (
                  <MenuItem key={snapshot.id} value={snapshot}>
                    {snapshot.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {firstSnapshot && secondSnapshot && (
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {`Showing Variance Between ${firstSnapshot.name} and ${secondSnapshot.name}`}
            </Typography>
          )}

          <div className={classes.container}>
            {topPickups.length > 0 && (
              <>
                <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px' }}>
                  Top Pickups
                </Typography>
                <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
                  <Table className={classes.table} aria-label="Top Pickups table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Value</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topPickups.map((row, index) => (
                        <TableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {formatDollar(row.value)}
                          </StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {topLosses.length > 0 && (
              <>
                <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px', marginTop: '10px' }}>
                  Top Losses
                </Typography>
                <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
                  <Table className={classes.table} aria-label="Top Losses table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Value</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topLosses.map((row, index) => (
                        <TableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {formatDollar(row.value)}
                          </StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </>
      )}
    </CardContent>
  );
};

export default OverUnderSummaryChart;
