import React, {useState, useEffect} from 'react';
import amiLogo from '../static/amiLogo.png';
import '../../project_dashboard/static/project-dashboard-styles.css';
import IconInfo from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@mui/icons-material/Email';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import Lottie from 'lottie-react';
import loadingAnimation from '../static/loadingAnimationLottie.json';
import concurLogo from '../static/concur_logo.svg'



import { Grid, Card, CardContent, Typography, Link, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle, Button } from '@mui/material';
import { Container } from 'react-bootstrap';


import HeaderComponent from '../Components/HeaderComponent';
import ProjectTeamTable from '../Components/ProjectTeamComponent';
import ProjectStageCard from '../Components/ProjectStatusComponent';
import TotalOwnerContractValue from '../Components/OwnerContractSummaryComponent';
import StartDateCard from '../Components/StartDateComponent';
import TCODateCard from '../Components/TCODateComponent';
import ProjectedEndDateCard from '../Components/ProjectedEndDate';
import ProjectTypeCard from '../Components/ProjectTypeComponent';
import SquareFootageCard from '../Components/SquareFootageComponent';
import PriceSFCard from '../Components/PricePerSFComponent';
import ProjectGrade from '../Components/ProjectGradeComponent';
import PercentComplete from '../Components/PercentCompleteComponent';
import ProgressTrackerTable from '../Components/ProgressTrackerComponent';
import OverUnderSummaryChart from '../Components/OverUnderComponent';
import PCOTypeSummary from '../Components/PCOTypeSummaryComponent';
import OwnerBillingsTable from '../Components/PaymentHistoryComponent';
import ProjectExternalLinks from '../Components/ProjectLinksComponent';
import CFASplit from '../Components/CFASplitComponent';
import KeyInfoTable from '../Components/KeyProjectInformationComponent';
import ProjectLocationMap from '../Components/ProjectLocationComponent';
import InfoIconButton from '../Components/InfoIconButtonComponent';
import TablePopoutButton from '../Components/TablePopoutIconComponent';
import BudgetSnapshotSelector from '../Components/ProjectSnapshotSelector';
import ProjectSettingsModal from '../Components/ProjectSettingsButton';
import ContractStrutureChart from '../Components/ContractStructureComponent';
import ConcurExpenseTracker from '../Components/ConcurExpenseTracker';
import ProfitPercentage from '../Components/CurrentProfitComponent';
import MasterProjectInfoCard from '../Components/MasterProjectInformationCard';
import MasterContractInfoCard from '../Components/MasterContractInformationCard';
import PercentCompleteBars from '../Components/PercentageCompleteBarsComponent';
import { format } from 'date-fns';
import ProfitDataChart from '../Components/ProfitChartComponent';
import FooterComponent from '../Components/FooterComponent';
import ARCOContingencyChart from '../Components/ArcoContingencyComponent';
import CostsVsBillingsChart from '../Components/CostsAndBillingsComponent';
import { initial } from 'lodash';
import DivisionSelect from '../Components/DivisionSelectorComponent';
import PortfolioProjectTeamTable from '../Components/ProjectTeamComponentForPortfolio';
import PortfolioMasterProjectInfoCard from '../Components/MasterProjectInformation-PortfolioComponent';
import FinancialSummaryComponent from '../Components/PortfolioCardFinancialSummaryComponent';

// Establish Today's Date and Date Options
const date_options = {
    weeday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}
const today_date = new Date().toLocaleDateString("en-US", date_options);

// Email All Button Styling
const useStyles = makeStyles((theme) => ({
    emailAllButton: {
      marginLeft: theme.spacing(1),
      backgroundColor: '#007bff',
      maxHeight: '30px'
    },
  }));

  
const ProjectDashboard = ({
    projects,
    companies,
    csrfToken,
    HUB_API_DOMAIN_NAME,
    apiKey,
    apiHeader,
    gmapsAPI,
    portfolioView,
    setPortfolioView,
    divisionOptions,
    selectedDivisions,
    setSelectedDivisions,
    stageOptions,
    selectedStages,
    setSelectedStages, 
    opsLeaderOptions,
    selectedOpsLeaders,
    setSelectedOpsLeaders,
    projectTypeOptions,
    selectedProjectTypes,
    setSelectedProjectTypes,
    accountOptions,
    selectedAccounts,
    setSelectedAccounts,
    onCompanySelect,
    selectedCompany,
    setSelectedCompany,
    handlePortfolioSearch,
    isLoading,

}) => {
    const classes = useStyles();
    // const [selectedCompany, setSelectedCompany] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); 
    const [filteredProjects, setFilteredProjects] = useState(projects);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [projectData, setProjectData] = useState({});
    const [contractBoxShadowColor, setContractBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [stageBoxShadowColor, setStageBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [budgetData, setBudgetData] = useState([])
    const [projectSnapshots, setProjectSnapshots] = useState({})
    const [sourceProcore, setSourceProcore] = useState('procore')
    const [selectedSnapshots, setSelectedSnapshots] = useState([]);
    const [changedSnapshots, setChangedSnapshots] = useState(null);
    const [initialSnapshots, setInitialSnapshots] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);


    // Mass Email Functions
    const getAllEmails = (roles) => {
        const emails = roles
          .filter(role => role.email)
          .map(role => role.email.toLowerCase())
          .filter((email, index, self) => self.indexOf(email) === index);
        return emails;
      };
    
      // Email All Handler
      const handleEmailAll = (emails, projectName) => {
        if (emails.length === 0) {
          alert("No team members have email addresses.");
          return;
        }
        const subject = encodeURIComponent(`Quick Question on ${selectedProject.project_number} ${selectedProject.name}`);
        const mailtoLink = `mailto:${emails.join(',')}`
          + `?subject=${subject}`
        window.location.href = mailtoLink;
      };
    
      // Extract all emails from budgetData
      const allEmails = getAllEmails(budgetData?.project_team || []);

    // Graph Logic
    const [showAllContData, setShowAllContData] = useState(false);
    const [showAllProfitData, setShowAllProfitData] = useState(false);

    const hasMoreThanSixMonthsData = () => {
        if (!budgetData || !budgetData.profit_data) return false;
      
        const earliestDate = new Date(budgetData[0].snapshot_date);
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      
        return earliestDate < sixMonthsAgo;
      };

// Checking for full data coming from API 
      const isProjectDataComplete = (project) => {
        return (
          project.project_number &&
          project.account_name &&
          project.owner_contact_summary
        );
      };

    // Handle company selection change -- Project View
    const handleCompanySelectChangeForProjectView = (event, newValue) => {
        setSelectedCompany(newValue);

        onCompanySelect(newValue);
    
        // If company selected, fetch its projects
        if (newValue) {
            fetchProjectsForCompany(newValue.company);
        } else {
            // If no company, show all projects
            setFilteredProjects(projects);
        }
    };
    // Handle selection change -- Portfolio View
    const handleCompanySelectChangeForPortfolioView = (event, newValue) => {
        setSelectedCompany(newValue);
        if (portfolioView) {
            handlePortfolioSearch();
        }
    };

    // Reset filteredProjects when dashboard mounts
    useEffect(() => {
        if (!selectedCompany && !searchQuery) {
            setFilteredProjects(projects);
        }
    }, [projects, selectedCompany, searchQuery]);

    // Reset all filters when we switch between Views
    const resetFilters = () => {
        setSelectedCompany(null);
        setSelectedProject(null);
        setSelectedProjectId(null);
        setSearchQuery("");
        setFilteredProjects(projects);
        setSelectedSnapshots([]);
        setInitialSnapshots([]);
        setInvoiceData([]);

        setSelectedDivisions([]);
        setSelectedStages([]);
        setSelectedOpsLeaders([]);
        setSelectedProjectTypes([]);
        setSelectedAccounts([]);
       
        // FYI: If you're hitting a weird error upon switch, make sure what's feeding your dropdown AutoCompletes is added here <------
      };
    useEffect(() => {
        if (portfolioView) {
            resetFilters();
        }
    }, [portfolioView]);
    

    // Handle search query change
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filtering projects based off of search query (selection)
        const filtered = projects.filter(project => {
            const matchesSearch = project.name.toLowerCase().includes(query);
            const matchesCompany = selectedCompany
                ? project.company === selectedCompany.company
                : true; 
            return matchesSearch && matchesCompany;
        });

        setFilteredProjects(filtered);
    };

    // Handle project selection
    const handleProjectSelectChange = (selectedProjectName) => {
        const project = filteredProjects.find(proj => proj.name === selectedProjectName);
        setSelectedProject(project);
        setSelectedProjectId(project ? project.id : null);
    };

// Fetching Project based on Company Selection
const fetchProjectsForCompany = (selectedCompanyName) => {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Auth': apiHeader,
            'api-key': apiKey,
        });

        fetch(`${HUB_API_DOMAIN_NAME}/phd/get_projects_and_companies/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                company_filter: selectedCompanyName,
                csrf_token: localStorage.getItem('hub_csrf_token')
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                console.log("INIT C&P DATA ::::")
                console.log(data)
                setFilteredProjects(data.projects || []);
            } else {
                console.error("Failed to fetch projects from HUB API: ", data.message)
            }
        })
        .catch(error => {
            console.error('Error fetching projects for company:', error);
        });
    };

// Combined Fetch for grabbing all initial data triggered off of projectID change
useEffect(() => {
    if (selectedProjectId) {
        const fetchData = async () => {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            try {
                const payload = {
                    project_id: selectedProjectId,
                    csrf_token: csrfToken,
                };

                // Call for projectData, projectSnapshots, and invoiceData concurrently
                const [projectResponse, settingsResponse, invoiceResponse] = await Promise.all([
                    fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_data/`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(payload),
                        credentials: 'include',
                    }),
                    fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_settings/`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(payload),
                        credentials: 'include',
                    }),
                    fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_owner_invoice_data/`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(payload),
                        credentials: 'include',
                    }),
                ]);

                const projectData = await projectResponse.json();
                const settingsData = await settingsResponse.json();
                const invoiceData = await invoiceResponse.json();

                // Handle Project Data
                if (projectData) {
                    setProjectData(projectData);
                }

                // Handle Project Settings (snapshots)
                if (settingsData.snapshots) {
                    const allSnapshots = Object.values(settingsData.snapshots).flat();
                    setInitialSnapshots(allSnapshots);

                    const initialSelectedSnapshots = allSnapshots.filter(snapshot => snapshot.selected);
                    setSelectedSnapshots(initialSelectedSnapshots);
                }

                // Handle Invoice Data
                if (invoiceData.data) {
                    setInvoiceData(invoiceData.data);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData();
    }
}, [selectedProjectId, csrfToken, apiHeader, apiKey]);

useEffect(() => {
    if (selectedProjectId && !selectedProject) {
      // Fetch the selected project data
      fetchProjectData(selectedProjectId);
    }
  }, [selectedProjectId, selectedProject]);

  const fetchProjectData = (projectId) => {
    // Fetch the project data from the server
    fetch(`${HUB_API_DOMAIN_NAME}/projects/${projectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Auth': apiHeader,
        'api-key': apiKey,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedProject(data);
        // ... handle other data as needed ...
      })
      .catch((error) => {
        console.error('Error fetching project data:', error);
      });
  };

// Separate useEffect for fetching budgetData whenever selectedSnapshots or selectedProjectId changes -- still getting called multiple times, but getting there
useEffect(() => {
    if (selectedProjectId && selectedSnapshots.length > 0) {
        const { actual_start_date, completion_date } = projectData.project_data || {};
        const formatted_start_date = actual_start_date ? new Date(actual_start_date).toISOString().split('T')[0] : null;
        const formatted_end_date = completion_date ? new Date(completion_date).toISOString().split('T')[0] : null;

        const fetchBudgetData = async () => {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            const requestBody = {
                start_date: formatted_start_date,
                end_date: formatted_end_date,
                project_id: selectedProjectId,
                selected_snapshots: selectedSnapshots.map(snapshot => snapshot.id),  
                csrf_token: csrfToken,
            };

            try {
                const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_budget_data/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(requestBody),
                    credentials: 'include',
                });

                const data = await response.json();
                if (data) {
                    setBudgetData(data || []);
                } else {
                    console.error("Failed to fetch project budget data from HUB API:", data.message);
                }
            } catch (e) {
                console.error('Error fetching budget data:', e);
            }
        };

        fetchBudgetData();
    }
}, [selectedProjectId, selectedSnapshots, csrfToken, apiHeader, apiKey]);

// ========= HELPER FUNCTIONS ==============

 // Selection Handler for Selection Snapshots    
 const handleSelectionChange = (updatedSnapshots) => {
    setSelectedSnapshots(updatedSnapshots);
    setInitialSnapshots(updatedSnapshots)
    console.log(":::::: UPDATED SNAPSHOTS ::::::::");
    console.log(updatedSnapshots);
};

// Track snapshot selection settings
const handleModalClose = async (selectedSnapshots) => {
    console.log("Attempting to push snapshots update....");

    const selectedIds = selectedSnapshots
        .filter(snapshot => snapshot.selected)
        .map(snapshot => snapshot.id); 

    if (selectedIds.length > 0) {
        try {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/save_project_settings/`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    project_id: selectedProjectId,
                    selected_snapshots: selectedIds,
                    csrf_token: csrfToken
                }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Snapshots updated successfully:', data);
            } else {
                console.error('Error updating snapshots:', data.message);
            }
        } catch (error) {
            console.error('Error making POST request:', error);
        }
    } else {
        console.log("No selected snapshots to update");
    }
};

// Controls box shadow color for Owner Contract Summary component
const handleContractSummaryColorChange = (color) => {
    if (color === 'green') {
        setContractBoxShadowColor('rgba(0, 128, 0, 0.5)')
    } else if (color === 'red') {
        setContractBoxShadowColor('rgba(255, 0, 0, 0.5)')
    } else {
        setContractBoxShadowColor('rgba(0, 0, 0, 0.10)')
    }
};
const handleStageColorChange = (color) => {
    setStageBoxShadowColor(color);
};

    console.log("===== Project Dash Console Checks =====")
    console.log("Project Data")
    console.log(projectData)
    console.log("===== Budget Check =====")
    console.log("Budget Data")
    console.log(budgetData)
    console.log("===== Settings Snapshot Data =====")
    console.log(selectedSnapshots)
    // console.log("===== INVOICE DATA =====")
    // console.log(invoiceData)
    // console.log("===== INITIAL SNAPSHOTS PD =====")
    // console.log(initialSnapshots)

    const renderProjectView = () => (
        <Grid id="main-container-proj" container spacing={1} style={{marginTop: '15px'}}>
         {/* Column 1 */}
         <Grid item xs={12} md={4}>
            <Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Information Summary</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                
                                {/* Warning Icon with Tooltip - shown when we're past subst comp but not in Warranty or Stopped Work stage */}
                                {projectData.project_data &&
                                    new Date(projectData.project_data.projected_finish_date) < new Date() &&
                                    projectData.project_data.stage !== "Warranty" &&
                                    projectData.project_data.stage !== "Stopped Work" && (
                                        <Tooltip style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}
                                        title="The projected finish date has passed, and the project is not in 'Warranty' or 'Stopped Work' stage. You can update this in your Procore admin panel."
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                },
                                            },
                                        }}>
                                            <WarningIcon style={{ color: 'red', margiinRight: '8px' }} />
                                        </Tooltip>
                                    )}

                                {/* Additional case: Warranty stage and more than 1 year from projected finish date. Should be complete */}
                                {projectData.project_data &&
                                    projectData.project_data.stage === "Warranty" &&
                                    new Date() > new Date(new Date(projectData.project_data.projected_finish_date).setFullYear(new Date(projectData.project_data.projected_finish_date).getFullYear() + 1)) && (
                                        <Tooltip 
                                        title="The project entered its 'Warranty' phase over a year ago. Are you sure this shouldn't be marked as complete? You can update this in your Procore admin panel."
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                },
                                            },
                                        }}
                                        >
                                            <WarningIcon style={{ color: 'orange', marginRight: '8px' }} />
                                        </Tooltip>
                                    )}
                                <InfoIconButton
                                    projectData={projectData}
                                    source="project-info-summary"
                                />

                            </div>
                    </div>

                            <MasterProjectInfoCard projectData={projectData} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Team</Typography>
                        <div className="project-team-icon-container">
                        {selectedProject ? (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EmailIcon />}
                                className={classes.emailAllButton}
                                onClick={() => handleEmailAll(allEmails, selectedProject.project_number, selectedProject.name, selectedProject.arco_id)}
                            >
                                Email All
                            </Button>
                            ) : null}
                            <InfoIconButton
                                projectData={projectData}
                                source="procore-team"
                                sourceProcore={sourceProcore}
                            />
                        </div>
                    </div>
                    <ProjectTeamTable
                        selectedProject={selectedProject}
                        budgetData={budgetData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>CFA Split</Typography>
                                    <InfoIconButton
                                            projectData={projectData}
                                            source="salesforce"
                                        />
                            </div>
                            <div style={{ height: '100%', maxHeight:'210px' }}>
                                <CFASplit projectData={projectData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content" style={{ flex: 1 }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top', marginLeft: "20px" }}>Percent Complete</Typography>
                            <div style={{ height:'222px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PercentCompleteBars projectData={projectData}
                                budgetData={budgetData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Location</Typography>
                        <ProjectLocationMap
                            projectData={projectData}
                            gmapsAPI={gmapsAPI}
                        />
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
            </Grid>

        {/* Column 2 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Contract Financial Summary
                                </Typography>
                                <InfoIconButton
                                    projectData={projectData}
                                    source="contract-financial-summary"
                                />
                            </div> 
                            <MasterContractInfoCard
                                projectData={projectData}
                                budgetData={budgetData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Contract Structure
                                </Typography>
                                <InfoIconButton
                                    projectData={projectData}
                                    source="contract-structure"
                                />
                            </div>
                            <ContractStrutureChart
                                budgetData={budgetData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'100%', marginBottom: '16px'}}>
                                <Typography variant="subtitle1" className="card-title">
                                    Profit
                                </Typography>
                            {budgetData && budgetData.profit_data && (
                                <Button variant="contained" className="toggle-primary-button" 
                                    onClick={() => setShowAllProfitData(!showAllProfitData)}>
                                    {showAllProfitData ? 'Show Last 6 Months' : 'Show All Data'}
                                </Button>
                            )}
                            </div>
                        <div style={{ width: '100%', height: '400px' }}>
                            <ProfitDataChart 
                                budgetData={budgetData} 
                                showAllProfitData={showAllProfitData}
                            />
                        </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'100%', marginBottom: '16px'}}>
                                <Typography variant="subtitle1" className="card-title">
                                    ARCO Contingency
                                </Typography>
                                {budgetData && budgetData.profit_data && (
                                    <Button
                                        variant="contained"
                                        className="toggle-primary-button"
                                        onClick={() => setShowAllContData(!showAllContData)}
                                    >
                                        {showAllContData ? 'Show Last 6 Months' : 'Show All Data'}
                                    </Button>
                                )}
                            </div>
                            <div style={{ width: '100%', height: '400px' }}>
                                <ARCOContingencyChart
                                budgetData={budgetData}
                                showAllContData={showAllContData}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Top Budget Pickups/Busts</Typography>
                            <TablePopoutButton 
                                budgetData={budgetData}
                                tableComponent={OverUnderSummaryChart}
                                selectedSnapshots={selectedSnapshots}
                                title={'Trade Over/Under Summary'}
                                source='trade over/under'
                            />
                        </div>
                        <OverUnderSummaryChart 
                            budgetData={budgetData}
                            isModal={false}
                            selectedSnapshots={selectedSnapshots}
                            selectedProject={selectedProject}
                        />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content" style={{marginLeft: "20px", marginRight: "20px"}}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>PCO Type Summary (Work in Progress)</Typography>
                    <div className="red-datawarehouse-banner" style={{position: 'relative', color: 'red'}}>
                        In Development
                    </div>
                    <PCOTypeSummary 
                        budgetData={budgetData}
                    /> 
                    </CardContent>
                </Card>
                </Grid>
               
            </Grid>
            </Grid>

        {/* Column 3 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px"}}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Cost vs Billings</Typography>
                    </div>
                    <CostsVsBillingsChart
                        budgetData={budgetData}
                        invoiceData={invoiceData}
                    />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Payment & Invoicing History
                                </Typography>
                                {/* <div className="red-datawarehouse-banner" style={{ position: 'relative', marginTop: '15px' }}>
                                    Awaiting VP Data
                                </div> */}
                                <TablePopoutButton
                                    budgetData={budgetData}
                                    tableComponent={OwnerBillingsTable}
                                    title={'Payment & Invoicing History'}
                                    invoiceData={invoiceData}
                                    selectedProject={selectedProject}
                                    source="payment-invoicing"
                                />
                            </div>
                            <OwnerBillingsTable 
                                isModal={false}
                                invoiceData={invoiceData}
                                selectedProject={selectedProject}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Concur Expense Tracker</Typography>
                                <TablePopoutButton 
                                    tableComponent={ConcurExpenseTracker}
                                    concurUrl={projectData?.project_data?.concur_expense_tracker_url}
                                    title={'Concur Expense Tracker'}
                                    source="concur-table"
                                />
                            </div>
                            {selectedProject && (
                                <>
                                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                <img 
                                    src={concurLogo} 
                                    alt="Concur Expense Tracker Image" 
                                    style={{ maxWidth: '10%', maxHeight: 'auto' }}
                                />
                                </div>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Expand to view the Concur expenses charging the project.</Typography>
                                </>
                            )}
                            {/* <ProgressTrackerTable style={{maxHeight: '150px'}}
                                isModal={true}
                            /> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Progress Tracker (Work in Progress)</Typography>
                            <div className="red-datawarehouse-banner" style={{position: 'relative', marginTop: '15px', color: 'red'}}>
                            Work In Progress
                            </div>
                                <TablePopoutButton 
                                    budgetData={budgetData}
                                    tableComponent={ProgressTrackerTable}
                                    title={'Progress Tracker'}
                                />
                            </div>
                            <ProgressTrackerTable style={{maxHeight: '150px'}}
                                isModal={false}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                </Grid>
            </Grid>
      </Grid>
    );

// ========= PORTFOLIO VIEW CONTAINER ==============
const renderPortfolioView = () => {
    // Check if a company is selected
    if (!selectedCompany) {
        return (
          <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '95vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" style={{color: 'red'}}>
                Please select a company to view projects.
              </Typography>
            </Grid>
          </Grid>
        );
      }
   // Check if loading
   if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', 
        }}
      >
        <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto'}}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Fetching projects...
            </Typography>
            <Lottie
              animationData={loadingAnimation}
              loop
              autoplay
              style={{ width: 'auto', height: '50vh' }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
    
      // Check if projects have full data
      if (
        !projects ||
        projects.length === 0 ||
        !isProjectDataComplete(projects[0])
      ) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh', 
                }}
                >
            <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Fetching projects...
            </Typography>
                <Lottie
                    animationData={loadingAnimation}
                    loop
                    autoplay
                    style={{ width: 'auto', height: '50vh' }}
                />
              
            </Grid>
          </Grid>
          </Box>
        );
      }

    //   Case where no projects for filters
      if (!isLoading && projects.length === 0) {
        return (
          <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '95vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No projects found for the selected filters.
              </Typography>
            </Grid>
          </Grid>
        );
      }

    //   Card Layout
    console.log("PROJECT DATA PORTFOLIO")
    console.log(projects)
    return (
        <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto', borderRadius: '10px'}}>
        {/* Map over all projects and assign to individual cards */}
        {projects.map((project) => (
            <Grid item xs={12} key={project.id}>
            <Card className="project-card">
                <CardContent className="project-card">
                {/* Card Header */}
                <div
                    className="portfolio-card-header"
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h6">
                    {project.project_number} | {project.name} | ARCO ID: {project.arco_id}
                    </Typography>
                    <Typography>

                    <a
                        href={`/phd?project_id=${project.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        View Project Details
                    </a>
                    </Typography>
                </div>

          {/* Card Components */}
          <div className="portfolio-card-information-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2.5}>
                <CardContent className="portfolio-component-card">
                        <PortfolioProjectTeamTable
                        projectNumber={project.project_number}
                        arcoID={project.arco_id}
                        projectName={project.name}
                        roles={project.project_team || []}
                        />
                    </CardContent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardContent className="portfolio-component-card">
                    <PortfolioMasterProjectInfoCard projectData={project} />
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={3}>
                <CardContent className="portfolio-component-card">
                    <FinancialSummaryComponent 
                        projectData={project}
                    />
                    </CardContent>
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <CardContent className="portfolio-component-card">
                   <div className="real-estate-banner" style={{color: 'red'}}>
                        Real Estate for Sale
                    </div>
                </CardContent>
              </Grid>
            </Grid>
          </div>
                
          {/* <div
            className="portfolio-card-footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <Typography>
                    Data Last Updated: Add Here
                </Typography>
            </div> */}
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>
   );
};

    return (
        <Container style={{ marginTop: '15px' }}>
            <div className="portfolio-view-control-btns-container">
                <label className="card-title">
                    <input
                        type="radio"
                        value="project"
                        checked={portfolioView === false}
                        onChange={() => setPortfolioView(false)}
                    />
                    Project View
                </label>
                <label className="card-title">
                    <input
                        type="radio"
                        value="portfolio"
                        checked={portfolioView === true}
                        onChange={() => setPortfolioView(true)}
                    />
                    Portfolio View
                </label>
            </div>
          {/* Header */}
          <Grid item xs={12}>
            <div className="header-container">
            <HeaderComponent
               selectedProject={selectedProject}
               projectData={projectData}
               today_date={today_date}
               selectedCompany={selectedCompany}
               companies={companies}
               handleCompanySelectChange={
                portfolioView
                ? handleCompanySelectChangeForPortfolioView
                : handleCompanySelectChangeForProjectView
            }
            filteredProjects={filteredProjects}
            handleProjectSelectChange={handleProjectSelectChange}
            setSelectedProjectId={setSelectedProjectId}
            csrfToken={csrfToken}
            selectedSnapshots={selectedSnapshots}
            handleSelectionChange={handleSelectionChange}
            handleModalClose={handleModalClose}
            selectedProjectId={selectedProjectId}
            initialSnapshots={initialSnapshots}
            portfolioView={portfolioView}

            divisionOptions={divisionOptions}
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}

            stageOptions={stageOptions}
            selectedStages={selectedStages}
            setSelectedStages={setSelectedStages}

            opsLeaderOptions={opsLeaderOptions}
            selectedOpsLeaders={selectedOpsLeaders}
            setSelectedOpsLeaders={setSelectedOpsLeaders}

            projectTypeOptions={projectTypeOptions}
            selectedProjectTypes={selectedProjectTypes}
            setSelectedProjectTypes={setSelectedProjectTypes}

            accountOptions={accountOptions}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}

            handlePortfolioSearch={handlePortfolioSearch}
         />
         </div>

          {/* Conditionally Render Main Content */}
          <div style={{ marginTop: '15px' }}>
            {portfolioView ? (
                // Render Portfolio View
                renderPortfolioView()
            ) : (
                // Render Project View
                renderProjectView()
            )}
            </div>
          </Grid>

           {!portfolioView && (
            <Grid style={{ marginTop: '40px' }}>
                <FooterComponent />
            </Grid>
        )}
     </Container>
  );
};
  
  export default ProjectDashboard;