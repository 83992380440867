import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';
import { formatDollar, formatDollarShort } from './utilityFunctions';

const ProfitDataChart = ({ budgetData, showAllProfitData }) => {
  if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) {
    return <Typography style={{ textAlign: 'center' }}>--</Typography>;
  }

  let chartData = budgetData.profit_data
    .filter(item => item.profit && item.snapshot_date)
    .map(item => ({
      snapshot_date: item.snapshot_date,
      profit: item.profit,
      profit_percentage: item.profit_percentage,
    }))
    .sort((a, b) => new Date(a.snapshot_date) - new Date(b.snapshot_date));

  // If not showing all data, limit to last 6 months
  if (!showAllProfitData) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    chartData = chartData.filter(
      (item) => new Date(item.snapshot_date) >= sixMonthsAgo
    );
  }

  const lineData = [{
    id: 'Profit Percentage',
    data: chartData.map(item => ({
      x: item.snapshot_date,
      y: item.profit_percentage,
    })),
  }];

  // Setting the min and max percentages here - profit
  const percentages = chartData.map((item) => item.profit_percentage);
  const minPercentage = Math.min(...percentages);
  const maxPercentage = Math.max(...percentages);

  // Subtract 3% from Lower, and add 1% to Max
  const adjustedMinPercentage = minPercentage - 3; 
  const adjustedMaxPercentage = maxPercentage + 1;

  // Ensure adjusted min does not go below zero 
    // NOTE: Add a separate case to handle multi-fam's loss leader jobs
  const finalMinPercentage = Math.max(adjustedMinPercentage, 0);

  const getTickValues = (dataLength) => {
    if (dataLength <= 10) {
      return chartData.map(item => item.snapshot_date);
    } else {
      const interval = Math.ceil(dataLength / 10);
      return chartData
        .map((item, index) => (index % interval === 0 ? item.snapshot_date : null))
        .filter(Boolean);
    }
  };

  return (
    <div style={{ height: '500px', position: 'relative' }}>
      {/* Bar Chart */}
      <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
        <ResponsiveBar
          data={chartData}
          keys={['profit']}
          indexBy="snapshot_date"
          margin={{ top: 5, right: 70, bottom: 180, left: 80 }}
          colors={['#083d61']}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Snapshot Date',
            legendPosition: 'middle',
            legendOffset: 60,
            tickValues: getTickValues(chartData.length),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Profit ($)',
            legendPosition: 'middle',
            legendOffset: -70,
            format: value => formatDollarShort(value),
          }}
          labelSkipWidth={50}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
          tooltip={({ indexValue, value, data }) => (
            <div
            style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}
            >
              <strong>Date: {indexValue}</strong>
              <br />
              Profit: {formatDollar(value)}
              <br />
              Profit Percentage: {data.profit_percentage ? data.profit_percentage.toFixed(2) : '--'}%
            </div>
          )}
          xScale={{ type: 'point' }}
          groupMode="grouped"
          padding={0.4}
          label={(d) => formatDollarShort(d.value)}
          theme={{
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>

      {/* Line Chart */}
      <div style={{ height: '100%', position: 'absolute', width: '100%', pointerEvents: 'none' }}>
        <ResponsiveLine
          data={lineData}
          margin={{ top: 5, right: 85, bottom: 180, left: 95 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: finalMinPercentage,
            max: adjustedMaxPercentage,
            stacked: false,
            reverse: false,
          }}
          axisLeft={null}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            legend: 'Profit Percentage (%)',
            legendPosition: 'middle',
            legendOffset: 55,
            format: value => `${value}%`,
          }}
          axisBottom={null}
          colors={['#54bef3']}
          pointSize={null}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={3}
          pointBorderColor={{ from: 'serieColor' }}
          enablePoints={true}
          enableArea={false}
          curve="monotoneX"
          enableGridX={false}
          enableGridY={false}
          theme={{
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProfitDataChart;
