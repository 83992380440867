import React from 'react';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CardContent } from '@mui/material';
// PDF Download Library
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';


const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD', 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0 
    }).format(roundedValue);
};

const useStyles = makeStyles({
    table: {
        minWidth: 500,
        whiteSpace: 'nowrap',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    statusIcon: {
        verticalAlign: 'middle',
        marginRight: '8px',
    },
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
        whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const calculatePaymentAge = (invoiceDate, payFullDate) => {
    if (!invoiceDate || !payFullDate) {
        return null;
    }
    const invoice = new Date(invoiceDate);
    const paid = new Date(payFullDate);

    if (isNaN(invoice) || isNaN(paid)) {
        return null;
    }

    const timeDiff = paid - invoice;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); 
}

const OwnerBillingsTable = ({ invoiceData, isModal, selectedProject }) => {
    const classes = useStyles();

    console.log("INVOICE DATA")
    console.log(invoiceData)

    if (!invoiceData || invoiceData.length === 0) {
        return <Typography style={{ textAlign: 'center' }}>--</Typography>;
    }

    const sortedInvoiceData = invoiceData.sort((a, b) => new Date(b.invoice_date) - new Date(a.invoice_date));

    // const generatePDF = () => {
    //     const doc = new jsPDF({
    //         orientation: 'landscape'
    //     });
    //     doc.text('Payment & Invoicing History Table', 14, 10);
    //     doc.text(`${selectedProject.project_number} - ${selectedProject.name}`, 14, 20);
        
    //     const tableColumn = ["Pay App Description", "Invoice Date", "Date Paid", "Payment Age", "Invoiced", "Paid", "Cumulative Paid"];
    //     const tableRows = [];

    //     sortedInvoiceData.forEach(row => {
    //         const paymentAge = row.pay_full_date && row.invoice_date ? calculatePaymentAge(row.invoice_date, row.pay_full_date) : '--';
    //         const rowData = [
    //             row.pay_app_description || '--',
    //             row.invoice_date || '--',
    //             row.pay_full_date || '--',
    //             paymentAge !== null ? `${paymentAge} days` : '--',
    //             row.invoiced ? formatDollar(row.invoiced) : '--',
    //             row.paid ? formatDollar(row.paid) : '--',
    //             row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
    //         ];
    //         tableRows.push(rowData);
    //     });

    //     doc.autoTable(tableColumn, tableRows, { startY: 25 });
    //     doc.save('owner_billings_table.pdf');
    // }
    // const generateExcel = () => {
    //     const worksheetData = sortedInvoiceData.map(row => ({
    //         'Pay App Description': row.pay_app_description || '--',
    //         'Invoice Date': row.invoice_date || '--',
    //         'Date Paid': row.pay_full_date || '--',
    //         'Payment Age': row.pay_full_date && row.invoice_date ? `${calculatePaymentAge(row.invoice_date, row.pay_full_date)} days` : '--',
    //         'Invoiced': row.invoiced ? formatDollar(row.invoiced) : '--',
    //         'Paid': row.paid ? formatDollar(row.paid) : '--',
    //         'Cumulative Paid': row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
    //     }));

    //     const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Payment and Invoicing');

    //     XLSX.writeFile(workbook, 'owner_billings_table.xlsx');
    // }


    return (
        <CardContent>
            {/* <div className="payment-table-buttons-group">
                <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.copyButton} 
                    onClick={generatePDF}
                >
                    Download PDF
                </Button>
                <Button 
                    variant="contained" 
                    className={classes.excelButton} 
                    onClick={generateExcel}
                >
                    Download Excel
                </Button>
            </div> */}
        <TableContainer className={isModal ? classes.tableContainer : ''} component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
            <Table className={classes.table} aria-label="owner billings table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell className="overunder-width-control">Pay App Description</StyledTableCell>
                        <StyledTableCell>Invoice Date</StyledTableCell>
                        <StyledTableCell>Date Paid</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center'}}>Payment Age</StyledTableCell> 
                        <StyledTableCell style={{textAlign: 'right'}}>Invoiced</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'right'}}>Paid</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'right'}}>Cumulative Paid</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedInvoiceData.map((row, index) => {
                        const paymentAge = row.pay_full_date && row.invoice_date ? calculatePaymentAge(row.invoice_date, row.pay_full_date) : null;
                        return (
                            <TableRow key={index}>
                                <StyledTableCell className="overunder-width-control" title={row.pay_app_description || '--'}>{row.pay_app_description || '--'}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.invoice_date ? row.invoice_date : '--'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.pay_full_date ? row.pay_full_date : '--'} 
                                </StyledTableCell>
                                <StyledTableCell style={{textAlign: 'center'}}>
                                    {paymentAge !== null ? (
                                        <Typography className={paymentAge >= 30 ? classes.red : classes.green}>
                                            {paymentAge} days
                                        </Typography>
                                    ) : (
                                        '--'
                                    )} 
                                </StyledTableCell>
                                <StyledTableCell 
                                    style={{ color: row.invoiced < 0 ? 'red' : 'inherit', textAlign: 'right' }}
                                >
                                    {row.invoiced ? formatDollar(row.invoiced) : '--'}
                                </StyledTableCell>
                                <StyledTableCell 
                                    style={{ color: row.paid < 0 ? 'red' : 'inherit', textAlign: 'right' }}
                                >
                                    {row.paid ? formatDollar(row.paid) : '--'}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{textAlign: 'right'}}
                                >{row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--'}</StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </CardContent>
);
};

export default OwnerBillingsTable;