import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import OverUnderSummaryChart from './OverUnderComponent';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { generatePDF, generateExcel } from './utilityFunctions';

const useStyles = makeStyles({
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const TablePopoutButton = ({ tableComponent: TableComponent, budgetData, invoiceData, title, source, selectedProject, selectedSnapshots,concurUrl }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    let tableDescription = ''
    let showButtons = null
    let viewWidth = 'lg';
    switch (source) {
        case 'trade over/under':
            tableDescription = 'This table shows JTD by default. The values below represent TOTAL variance by cost code.'
             viewWidth = 'lg'
            break;
        case 'payment-invoicing':
            showButtons = true
            tableDescription = "This table displays the project's invoicing history from Veiwpoint."
             viewWidth = 'lg'
            break;
        case 'concur-table':
            viewWidth = 'xl'
            break;
        default:
            tableDescription = ''; 
            break;
    }


    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <TableChartIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose} maxWidth={viewWidth} fullWidth>
                <DialogTitle className='titillium-web-base' style={{fontSize: '30px', fontWeight: 'bolder'}}>{title}</DialogTitle>
                <DialogContent className='titillium-web-base'>{tableDescription}</DialogContent>
                <DialogContent style={{ height: '70vh', display: 'flex', flexDirection: 'column' }}>
                {showButtons && (
                    <DialogActions className='payment-table-buttons-container'>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            className="pdf-button"
                            onClick={() => generatePDF(invoiceData, selectedProject)} 
                        >
                            Download PDF
                        </Button>
                        <Button 
                            variant="contained" 
                            className="excel-button"
                            onClick={() => generateExcel(invoiceData)} 
                        >
                            Download Excel
                        </Button>
                </DialogActions>
                )}
                    <TableComponent 
                        budgetData={budgetData} 
                        invoiceData={invoiceData}
                        selectedSnapshots={selectedSnapshots}
                        concurUrl={concurUrl}
                        isModal={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TablePopoutButton;
