import React from 'react';
import { Container, Typography } from '@mui/material';
import '../../project_dashboard/static/project-dashboard-styles.css';

const FooterComponent = () => {
    return (
        <footer className="footer-content">
            <Container>
            <Typography variant="body2" align="center" color="white">
            Built by ARCO/Murray Innovation. 
            
            For any questions, feedback, or to report any innacuracies in data, please email us at <a href="innovation@arcomurray.com" target="_blank" style={{color: "white"}}>innovation@arcomurray.com</a>.
                </Typography>
                {/* <br></br>
                <Typography variant="body2" align="center" color="white">
                    &copy; {new Date().getFullYear()} ARCO/Murray Design Build. All rights reserved.
                </Typography> */}
            </Container>
        </footer>
    );
}

export default FooterComponent;
