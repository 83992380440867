import React, { useEffect, useState } from 'react';
import { HUB_URL } from '../../utils/config/config';
import ProjectDashboard from '../../components/project_dashboard/Containers/project_dashboard';

const API_AUTH_HEADER = process.env.REACT_APP_API_AUTH_HEADER;
const API_KEY = process.env.REACT_APP_API_KEY;
const HUB_API_DOMAIN_NAME = process.env.REACT_APP_HUB_API_DOMAIN_NAME;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const PHD = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [csrfToken, setCsrfToken] = useState(null);
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [portfolioView, setPortfolioView] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    // Portfolio View Filters
    const [divisionOptions, setDivisionOptions] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);

    const [stageOptions, setStageOptions] = useState([]);
    const [selectedStages, setSelectedStages] = useState([]);

    const [opsLeaderOptions, setOpsLeaderOptions] = useState([]);
    const [selectedOpsLeaders, setSelectedOpsLeaders] = useState([]);

    const [projectTypeOptions, setProjectTypeOptions] = useState([]);
    const [selectedProjectTypes, setSelectedProjectTypes] = useState([]);
    
    const [accountOptions, setAccountOptions] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    // UseEffect to call Portfolio Search when any of the dropdowns are updated
    useEffect(() => {
        if (portfolioView && selectedCompany) {
          // Clear projects and set loading state
          setProjects([]);
          setIsLoading(true);
      
          handlePortfolioSearch();
        }
      }, [
        portfolioView,
        selectedCompany,
        selectedDivisions,
        selectedStages,
        selectedOpsLeaders,
        selectedProjectTypes,
        selectedAccounts,
      ]);

    // Send message to parent to establish trust
    useEffect(() => {
        window.parent.postMessage(window.location.origin, HUB_URL);
    }, []);

    // Listen for messages from parent to set CSRF token
    useEffect(() => {
        const messageListener = (event) => {
            if (event.origin === HUB_URL) {
                const token = event.data.token;
                setCsrfToken(token);
            }
        };

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, []);

    // Handle Company Selection -- Received from ProjectDashboard
    const handleCompanySelect = (company) => {
        setSelectedCompany(company); 
    };

    // Fetching for Standard Project View
    useEffect(() => {
        if (csrfToken && !portfolioView) {
            const urlParams = new URLSearchParams(window.location.search);
            const user_uid = urlParams.get('user_uid');
            localStorage.setItem('hub_csrf_token', csrfToken);

            if (user_uid) {
                const headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.set('X-CSRFToken', csrfToken);
                headers.append('Auth', API_AUTH_HEADER);
                headers.append('api-key', API_KEY);

                // Perform Authentication Check
                fetch(`${HUB_URL}/phd/auth_check/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        user_uid: user_uid,
                        csrf_token: localStorage.getItem('hub_csrf_token'),
                    }),
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(authData => {
                    if (authData.auth_check_passed) {
                        setAuthenticated(true);

                        // Fetch Projects and Companies
                        const body = {
                            csrf_token: localStorage.getItem('hub_csrf_token'),
                            user_uid: user_uid,
                            portfolio_view: portfolioView,
                        };

                        fetch(`${HUB_URL}/phd/get_projects_and_companies/`, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(body),
                            credentials: 'include',
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setProjects(data.projects || []);
                                setCompanies(data.companies || []);
                            } else {
                                console.error("Failed setting Projects and Companies:", data);
                            }
                        })
                        .catch(error => {
                            console.error('Error in fetching projects and companies:', error);
                        });
                    } else {
                        console.error('Token validation failed:', authData);
                    }
                })
                .catch(error => {
                    console.error('Error during auth check:', error);
                });
            }
        }
    }, [csrfToken, portfolioView]);

    // Fetching for Portfolio View
    const handlePortfolioSearch = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const user_uid = urlParams.get('user_uid');
      
        if (user_uid && portfolioView && selectedCompany) {
          const headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.set('X-CSRFToken', csrfToken);
          headers.append('Auth', API_AUTH_HEADER);
          headers.append('api-key', API_KEY);
      
          // Clear projects list and set loading state
          setProjects([]);
          setIsLoading(true);
      
          // Perform Authentication Check
          fetch(`${HUB_URL}/phd/auth_check/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              user_uid: user_uid,
              csrf_token: localStorage.getItem('hub_csrf_token'),
            }),
            credentials: 'include',
          })
          .then(response => response.json())
          .then(authData => {
            if (authData.auth_check_passed) {
              setAuthenticated(true);
      
              const body = {
                csrf_token: localStorage.getItem('hub_csrf_token'),
                user_uid: user_uid,
                portfolio_view: portfolioView,
                company_filter: selectedCompany.company,
              };
      
              // Add optional filters IF they have values
              if (selectedDivisions.length > 0) body.division_filter = selectedDivisions;
              if (selectedStages.length > 0) body.stage_filter = selectedStages;
              if (selectedOpsLeaders.length > 0) body.ops_leader_filter = selectedOpsLeaders;
              if (selectedProjectTypes.length > 0) body.project_type_filter = selectedProjectTypes;
              if (selectedAccounts.length > 0) body.account_filter = selectedAccounts;
      
              fetch(`${HUB_URL}/phd/get_projects_and_companies/`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
                credentials: 'include',
              })
              .then(response => response.json())
              .then(data => {
                if (data) {
                  setProjects(data.projects || []);
                  setCompanies(data.companies || []);
      
                  // Set filter options if available
                  if (data.filter_dropdowns) {
                    setDivisionOptions(data.filter_dropdowns.divisions || []);
                    setStageOptions(data.filter_dropdowns.stages || []);
                    setOpsLeaderOptions(data.filter_dropdowns.ops_leaders || []);
                    setProjectTypeOptions(data.filter_dropdowns.project_types || []);
                    setAccountOptions(data.filter_dropdowns.accounts || []);
                  }
                } else {
                  console.error("Failed setting Projects and Companies:", data);
                }
                setIsLoading(false); 
              })
              .catch(error => {
                console.error('Error in fetching projects and companies:', error);
                setIsLoading(false);
              });
            } else {
              console.error('Token validation failed:', authData);
              setIsLoading(false); 
            }
          })
          .catch(error => {
            console.error('Error during auth check:', error);
            setIsLoading(false); 
          });
        } else {
          console.log("Portfolio search triggered but user_uid or company not available.");
        }
      };

    if (!authenticated) {
        return (
            <div>
                <div>Loading...</div>
            </div>
        );
    }

    return (
        <div>
            <ProjectDashboard 
                companies={companies} 
                projects={projects}
                csrfToken={csrfToken} 
                HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                apiKey={API_KEY}
                apiHeader={API_AUTH_HEADER}
                gmapsAPI={GOOGLE_API_KEY}

                portfolioView={portfolioView}
                setPortfolioView={setPortfolioView}

                divisionOptions={divisionOptions}
                selectedDivisions={selectedDivisions}
                setSelectedDivisions={setSelectedDivisions}

                stageOptions={stageOptions}
                selectedStages={selectedStages}
                setSelectedStages={setSelectedStages}
        
                opsLeaderOptions={opsLeaderOptions}
                selectedOpsLeaders={selectedOpsLeaders}
                setSelectedOpsLeaders={setSelectedOpsLeaders}
                
                projectTypeOptions={projectTypeOptions}
                selectedProjectTypes={selectedProjectTypes}
                setSelectedProjectTypes={setSelectedProjectTypes}
                
                accountOptions={accountOptions}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                onCompanySelect={handleCompanySelect}
                handlePortfolioSearch={handlePortfolioSearch}
                isLoading={isLoading} 
            />
        </div>
    );
};

export default PHD;
