import React from "react";

import { CardContent, Typography } from "@material-ui/core";

const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
};

const SubstantialCompletionDateCard = ({ projectData }) => {
    const substantComplete = projectData?.project_data?.projected_finish_date || projectData?.projected_finish_date


return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>
                {formatDate(substantComplete) || '--' }
            </Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default SubstantialCompletionDateCard;