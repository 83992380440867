import React, {useState, useEffect } from 'react';
import { CardContent, Autocomplete, TextField, FormControlLabel, Checkbox, Button, Collapse, Link } from '@mui/material';
import { filter } from 'lodash';

const ProjectSelect = ({ filteredProjects, selectedProject, handleProjectSelectChange, setSelectedProjectId, csrfToken }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isCompletedChecked, setIsCompletedChecked] = useState(false);
    const [displayedProjects, setDisplayedProjects] = useState(filteredProjects);

    useEffect(() => {
        if (selectedProject) {
            setSelectedOption(selectedProject.name);
        } else {
            setSelectedOption('');
        }
    }, [selectedProject]);

    useEffect(() => {
        filterProjects();
    }, [isCompletedChecked, filteredProjects]);

    // handle changes, and clearing of the selected project
    const handleChange = (event, newValue) => {
        const newSelectedOption = newValue ? newValue.name : '';
        setSelectedOption(newSelectedOption);
        handleProjectSelectChange(newSelectedOption);

        if (newValue) {
            setSelectedProjectId(newValue.id)
        }
    };

    const sortAlphaNumbersLast = (a, b) => {
        const isANumber = /^\d/.test(a.name);
        const isBNumber = /^\d/.test(b.name);

        if (isANumber && !isBNumber) return 1;
        if (!isANumber && isBNumber) return -1;

        return a.name.localeCompare(b.name);
    };

    // Filter out Complete if checked -- all projects by default
    const filterProjects = () => {
        let filteredProjectList = [...filteredProjects];

        if (isCompletedChecked) {
            filteredProjectList = filteredProjects.filter(project => project.stage_group !== 'Complete');
        }

        // Alphabetical sorting by project_name
        filteredProjectList.sort(sortAlphaNumbersLast);

        setDisplayedProjects(filteredProjectList);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '250px'}}>
        <Autocomplete
            value={selectedOption ? displayedProjects.find(project => project.name === selectedOption) || null : null}
            onChange={handleChange}
            options={displayedProjects}
            getOptionLabel={(option) => `${option.project_number} - ${option.name}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Project"
                    variant="outlined"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white',
                            },
                            '&:hover fieldset': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        },
                        '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    }}
                />
            )}
            isOptionEqualToValue={(option, value) => option.name === value.name}
        />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isCompletedChecked}
                        onChange={(e) => setIsCompletedChecked(e.target.checked)}
                        sx={{
                            color: 'white',
                            '&.Mui-checked': {
                                color: 'white',
                            },
                        }}
                    />
                }
                label="Exclude completed"
                sx={{
                    color: 'white',
                    marginLeft: '5px',
                    width: '100%'
                }}
            />
        </div>

    </div>
);
}

export default ProjectSelect;