import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';
import { formatDollar, formatDollarShort } from './utilityFunctions';

const ARCOContingencyChart = ({ budgetData, showAllContData }) => {
  if (!budgetData || !budgetData.contingency_data || budgetData.contingency_data.length === 0 ) {
    return <Typography style={{ textAlign: 'center' }}>--</Typography>;
  }

  // Prepare data for Nivo Bar Chart
  let chartData = budgetData.contingency_data
    .map((item) => ({
      snapshot_date: item.snapshot_date,
      contingency: item.contingency,
      percentage_of_contract: item['% of contract'],
    }))
    .sort((a, b) => new Date(a.snapshot_date) - new Date(b.snapshot_date));

  // If not showing all data, limit to last 6 months
  if (!showAllContData) {
    // Calculate date 6 months ago
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // Filter chartData to include only entries from the last 6 months
    chartData = chartData.filter(
      (item) => new Date(item.snapshot_date) >= sixMonthsAgo
    );
  }

  const lineData = [{
      id: 'Percentage of Contract',
      data: chartData.map((item) => ({
        x: item.snapshot_date,
        y: item.percentage_of_contract,
      })),
    }];

  // Setting the min and max percentages here
    const percentages = chartData.map((item) => item.percentage_of_contract);
    const minPercentage = Math.min(...percentages);
    const maxPercentage = Math.max(...percentages);

  // Subtract 3% from Lower, and add 1% to Max
  const adjustedMinPercentage = minPercentage - 3; 
  const adjustedMaxPercentage = maxPercentage + 1;

  // Ensure adjusted min does not go below zero 
    // NOTE: Add a separate case to handle multi-fam's loss leader jobs
  const finalMinPercentage = Math.max(adjustedMinPercentage, 0);


  // Adjust Left y-axis $ amounts 
  // const contingencies = chartData.map((item) => item.contingency);
  // const minContingency = Math.min(...contingencies);
  // const maxContingency = Math.max(...contingencies);

  // // Decrease Lower by 3%
  // const adjustedMinContingency = minContingency * 0.97; 
  // // Increase Upper by 1%
  // const adjustedMaxContingency = maxContingency * 1.01; 



  // Function to calculate tick values for the x-axis
  const getTickValues = (dataLength) => {
    if (dataLength <= 10) {
      // Show all tick values if 10 or fewer data points
      return chartData.map((item) => item.snapshot_date);
    } else {
      // Otherwise, show every nth tick to prevent overcrowding
      const interval = Math.ceil(dataLength / 10);
      return chartData
        .map((item, index) =>
          index % interval === 0 ? item.snapshot_date : null
        )
        .filter(Boolean);
    }
  };

  return (
    <div style={{ height: '500px', position: 'relative' }}>
      {/* Bar Chart */}
      <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
        <ResponsiveBar
          data={chartData}
          keys={['contingency']}
          indexBy="snapshot_date"
          margin={{ top: 5, right: 70, bottom: 180, left: 80 }}
          padding={0.4}
          colors={['#083d61']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          borderRadius={2}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Snapshot Date',
            legendPosition: 'middle',
            legendOffset: 65,
            tickValues: getTickValues(chartData.length),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Total Contingency ($)',
            legendPosition: 'middle',
            legendOffset: -72,
            format: (value) => formatDollarShort(value),
          }}
          labelSkipWidth={50}
          labelSkipHeight={35}
          label={(d) => formatDollarShort(d.value)}
          labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
          tooltip={({ indexValue, value, data }) => (
            <div
            style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}
            >
              <strong>Date: {indexValue}</strong>
              <br />
              Contingency: {formatDollarShort(value)}
              <br />
              % of Contract: {data.percentage_of_contract.toFixed(2)}%
            </div>
          )}
          xScale={{ type: 'point' }}
          theme={{
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>

      {/* Line Chart Overlay */}
      <div style={{
          height: '100%',position: 'absolute', width: '100%', pointerEvents:'none',}}>
        <ResponsiveLine
          data={lineData}
          margin={{ top: 5, right: 85, bottom: 180, left: 95 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: finalMinPercentage,
            max: adjustedMaxPercentage,
            stacked: false,
            reverse: false,
          }}
          axisLeft={null}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            legend: '% of Contract',
            legendPosition: 'middle',
            legendOffset: 55,
            format: (value) => `${value}%`,
          }}
          axisBottom={null}
          colors={['#54bef3']}
          pointSize={null}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          enablePoints={true}
          enableArea={false}
          enableSlices="x"
          curve="monotoneX"
          enableGridX={false}
          enableGridY={false}
          theme={{
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ARCOContingencyChart;
