import React, {useState, useEffect } from 'react';
import { Autocomplete, TextField, FormControlLabel, Checkbox} from '@mui/material';


const DivisionSelect = ({ options, selectedValues, onChange }) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '250px'}}>
          <Autocomplete
            multiple
            value={selectedValues || []}
            onChange={(event, newValue) => onChange(newValue)}
            options={options || []}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Divisions"
                variant="outlined"
                fullWidth
                sx={{
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'white',
                    '&.Mui-focused': {
                      color: 'white',
                    },
                  },
                  '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  // Selected Values AKA "Chips" Styling 
                  '& .MuiChip-root': {
                    color: 'white', 
                    backgroundColor: 'transparent',
                    border: '1px solid lightblue',
                  },
                  '& .MuiChip-deleteIcon': {
                    color: 'white', 
                  },
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option === value}
          />
        </div>
      );
    };

export default DivisionSelect;