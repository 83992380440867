import {useState, useEffect} from "react";
import bonusToolIcon from '../static/bonus_tool_icon.svg';
import "../static/project-dashboard-styles.css"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Typography, CardContent, Link } from "@mui/material";
import ProjectExternalLinks from "./ProjectLinksComponent";
import ProjectSelect from "./ProjectSelectComponent";
import CompanySelect from "./CompanySelectorComponent";
import BudgetSnapshotSelector from "./ProjectSnapshotSelector";
import ProjectSettingsModal from "./ProjectSettingsButton";
import DivisionSelect from "./DivisionSelectorComponent";
import ProjectStageSelect from "./ProjectStageSelectorComponent";
import OpsLeaderSelect from "./OpsLeaderSelectorComponent";
import ProjectTypeSelect from "./ProjectTypeSelectorComponent";
import AccountSelect from "./AccountSelectorComponent";



const HeaderComponent = ({ 
    selectedProject,
    projectData,
    today_date,
    selectedCompany,
    companies,
    handleCompanySelectChange,
    filteredProjects,
    handleProjectSelectChange,
    setSelectedProjectId,
    csrfToken, 
    selectedSnapshots,
    handleSelectionChange,
    handleModalClose,
    selectedProjectId,
    initialSnapshots, 
    portfolioView,
    divisionOptions,
    selectedDivisions,
    setSelectedDivisions,
    stageOptions,
    selectedStages,
    setSelectedStages,
    opsLeaderOptions,
    selectedOpsLeaders,
    setSelectedOpsLeaders,
    projectTypeOptions,
    selectedProjectTypes,
    setSelectedProjectTypes,
    accountOptions,
    selectedAccounts,
    setSelectedAccounts,
    handlePortfolioSearch
    
    }) => {
    
    // Latest Snapshot for Data Updated
    const [mostRecentCreatedAt, setMostRecentCreatedAt] = useState(null);

    useEffect(() => {
        if (selectedSnapshots && selectedSnapshots.length > 0) {
            const mostRecent = selectedSnapshots.reduce((latest, snapshot) => {
                return new Date(snapshot.created_at) > new Date(latest) ? snapshot.created_at : latest;
            }, selectedSnapshots[0].created_at);

            setMostRecentCreatedAt(new Date(mostRecent).toLocaleDateString());
        } else {
            setMostRecentCreatedAt(null);
        }
    }, [selectedSnapshots]);

    // Track state changes in portfolio
    useEffect(() => {
        if (portfolioView) {
            // console.log("Header Comp - Switched to Portfolio View");
           
        } else {
            // console.log("Header Comp - Switched to Project View");
           
        }
    }, [portfolioView]);

    console.log(selectedCompany)
    
    return (
            <CardContent className="title-block-content">
            <div className="dashboard-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '10px' }}>
                <div className="dashboard-selectors-container" style={{ display: 'flex', flex: 1, gap: '20px', alignItems: 'flex-start' }}>
                    <div className='dashboard-selector' style={{ flex: 1 }}>
                        <CompanySelect
                            selectedCompany={selectedCompany}
                            options={companies}
                            handleSelectChange={handleCompanySelectChange}
                            portfolioView={portfolioView}
                        />
                    </div>
                    {/* Conditionally show the project dropdown or new dropdowns based on portfolioView */}
                    {portfolioView ? (
                        <>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <DivisionSelect
                                    options={divisionOptions}
                                    selectedValues={selectedDivisions}
                                    onChange={(newValues) => {
                                        setSelectedDivisions(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <ProjectStageSelect
                                    options={stageOptions}
                                    selectedValues={selectedStages}
                                    onChange={(newValues) => {
                                        setSelectedStages(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <OpsLeaderSelect
                                    options={opsLeaderOptions}
                                    selectedValues={selectedOpsLeaders}
                                    onChange={(newValues) => {
                                        setSelectedOpsLeaders(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <ProjectTypeSelect
                                    options={projectTypeOptions}
                                    selectedValues={selectedProjectTypes}
                                    onChange={(newValues) => {
                                        setSelectedProjectTypes(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <AccountSelect
                                    options={accountOptions}
                                    selectedValues={selectedAccounts}
                                    onChange={(newValues) => {
                                        setSelectedAccounts(newValues);
                                    }}
                                />
                            </div>
                        
                        <div className="csr-report-link-container">
                                {selectedCompany ?(
                                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
                                      <a href={`https://hub.arcomurray.com/reports/confidentiality/view/?company=${selectedCompany.company}`} className="csr-report-link" target="_blank" rel="noopener noreferrer">
                                        Confidentiality Sales Report
                                        <OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
                                        </a>
                                    </div>
                                ) : (
                                    null
                                )}
                        </div>
                            
                        </>
                    ) : (
                        <div className='dashboard-selector' style={{ flex: 1 }}>
                            <ProjectSelect
                                filteredProjects={filteredProjects}
                                selectedProject={selectedProject}
                                handleProjectSelectChange={handleProjectSelectChange}
                                setSelectedProjectId={setSelectedProjectId}
                                csrfToken={csrfToken}
                            />
                        </div>
                    )}
                </div>
            </div>
            {!portfolioView && (
                selectedProject ? (
                    <div className='project-information-container'>
                    <div className='project-account-container'>
                        <Typography variant='h5' className="titillium-web-base project-header-title-text-large">{selectedProject.name}</Typography>
                        <Typography variant='h5' className="titillium-web-base project-header-title-text-large">({projectData.project_data?.account_name})</Typography>
                    </div>
                    <Typography variant='h5' className="titillium-web-base project-header-title-text-large">ARCO ID: {projectData.project_data?.arco_id}</Typography>
                    </div>
                ) : (
                    <div className='project-information-container' style={{marginBottom: '20px'}}>
                    <Typography variant='h6' className="titillium-web-base project-header-title-text" style={{ marginTop: '20px', textAlign: 'center' }}>
                        Please Select a Company and Project to View Data.
                    </Typography>
                    </div>
                )
                )}
                
          
                {!portfolioView && selectedProject && (
                    <div className="project-links-container" style={{ flex: 1 }}>
                        {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && mostRecentCreatedAt && (
                        <Typography variant='h6' className="titillium-web-base project-header-title-text">
                            Latest Budget Snapshot: {mostRecentCreatedAt}
                        </Typography>
                        )}

                        {selectedProject && (
                        <div className="bonus-tool-container" style={{ display: 'none' }}>
                            <Typography variant='h6' className="titillium-web-base">
                            Bonus Model
                            </Typography>
                            <Link href='#' target="_blank" variant='h6' className="titillium-web-base">
                            <img src={bonusToolIcon} className="bonus-tool-icon" />
                            </Link>
                        </div>
                        )}

                        <ProjectExternalLinks
                        selectedProjectId={selectedProjectId}
                        projectData={projectData}
                        />

                        {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ color: 'white', textAlign: 'left', verticalAlign: 'top' }}>
                            Budget Snapshot Selector
                            </Typography>
                            <ProjectSettingsModal
                            Component={BudgetSnapshotSelector}
                            initialSnapshots={initialSnapshots}
                            title={'Budget Snapshot Selector'}
                            selectedSnapshots={selectedSnapshots}
                            onSelectionChange={handleSelectionChange}
                            handleModalClose={handleModalClose}
                            />
                        </div>
                        )}
                    </div>
                    )}
        </CardContent>
    );
};

export default HeaderComponent;