import React from 'react';
import { CardContent, Typography, Grid, IconButton, Tooltip } from '@material-ui/core';
import ThreePIcon from '@mui/icons-material/ThreeP';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.1rem',
    marginRight: theme.spacing(0.5),
    color: '#003865',
  },
  nameWithMargin: {
    marginRight: theme.spacing(0.75),
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      color: '#51a8ff',
    },
  },
  iconButton: {
    color: 'grey',
    '&:hover': {
      color: '#51a8ff',
    },
  },
}));

const getFirstRecordWithEmail = (roles, roleName) => {
  const role = roles.find((r) => r.role === roleName);
  return role ? { name: role.name, email: role.email } : { name: '--', email: '' };
};

const PortfolioProjectTeamTable = ({ projectNumber, arcoID, projectName, roles }) => {
  const classes = useStyles();

  const renderRole = (label, roleName) => {
    const { name, email } = getFirstRecordWithEmail(roles, roleName);
    const arconnectLink = email ? `https://arconnect.arco1.com/people/${email.split('@')[0]}` : '';
    return (
      <>
        <span className={classes.label}>{label}:</span>
        {email ? (
          <a href={arconnectLink} target="_blank" rel="noopener noreferrer" className={classes.nameWithMargin}>
            {name}
          </a>
        ) : (
          <span className={classes.nameWithMargin}>{name}</span>
        )}
        {email && (
          <>
            <Tooltip title={`Teams ${name}`}>
              <a href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`} target="_blank" rel="noopener noreferrer">
                <IconButton size="small" className={classes.iconButton}>
                  <ThreePIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title={`Email ${name}`}>
              <a
                href={`mailto:${email}?subject=Question on ${projectNumber} ${projectName} | ARCO ID: ${arcoID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton size="small" className={classes.iconButton}>
                  <EmailIcon />
                </IconButton>
              </a>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  return (
    <CardContent>
      {roles ? (
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography className="project-team-typography">{renderRole('PM', 'Project Manager')}</Typography>
            <Typography className="project-team-typography">{renderRole('Super', 'Superintendent')}</Typography>
            <Typography className="project-team-typography">{renderRole('PC', 'Project Coordinator')}</Typography>
            <Typography className="project-team-typography">{renderRole('Project Exec', 'Project Executive')}</Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography className="project-team-typography">{renderRole('Project Exec', 'Project Executive')}</Typography> */}
            {/* <Typography className="project-team-typography">{renderRole('Gen Super', 'General Superintendent')}</Typography>
            <Typography className="project-team-typography">{renderRole('Accountant', 'Project Accountant')}</Typography> */}
          {/* </Grid> */}
        </Grid>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">No project team data available</Typography>
        </div>
      )}
    </CardContent>
  );
};

export default PortfolioProjectTeamTable;
