import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Helper function to generate a PDF
export const generatePDF = (sortedInvoiceData, selectedProject) => {
    const doc = new jsPDF({
        orientation: 'landscape'
    });
    doc.text('Payment & Invoicing History Table', 14, 10);
    doc.text(`${selectedProject.project_number} - ${selectedProject.name}`, 14, 20);

    const tableColumn = ["Pay App Description", "Invoice Date", "Date Paid", "Payment Age", "Invoiced", "Paid", "Cumulative Paid"];
    const tableRows = [];

    sortedInvoiceData.forEach(row => {
        const paymentAge = row.pay_full_date && row.invoice_date ? calculatePaymentAge(row.invoice_date, row.pay_full_date) : '--';
        const rowData = [
            row.pay_app_description || '--',
            row.invoice_date || '--',
            row.pay_full_date || '--',
            paymentAge !== null ? `${paymentAge} days` : '--',
            row.invoiced ? formatDollar(row.invoiced) : '--',
            row.paid ? formatDollar(row.paid) : '--',
            row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
        ];
        tableRows.push(rowData);
    });

    autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 25,
    });
    doc.save('owner_billings_table.pdf');
};

// Helper function to generate an Excel file
export const generateExcel = (sortedInvoiceData) => {
    const worksheetData = sortedInvoiceData.map(row => ({
        'Pay App Description': row.pay_app_description || '--',
        'Invoice Date': row.invoice_date || '--',
        'Date Paid': row.pay_full_date || '--',
        'Payment Age': row.pay_full_date && row.invoice_date ? `${calculatePaymentAge(row.invoice_date, row.pay_full_date)} days` : '--',
        'Invoiced': row.invoiced ? formatDollar(row.invoiced) : '--',
        'Paid': row.paid ? formatDollar(row.paid) : '--',
        'Cumulative Paid': row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payment and Invoicing');

    XLSX.writeFile(workbook, 'owner_billings_table.xlsx');
};

// Utility function for payment age 
export const calculatePaymentAge = (invoiceDate, payFullDate) => {
    if (!invoiceDate || !payFullDate) return '--';
    const invoice = new Date(invoiceDate);
    const paid = new Date(payFullDate);
    const timeDiff = paid - invoice;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); 
};


// Formatting Number
export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

// Format Date in MM/DD/YYYY format
export const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
  };

// Formats number to nearest USD amount (no cents)
export const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
};

// Truncates Large Numbers to B, M, 100k, k, etc...
export const formatDollarShort = (value) => {
    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    if (absValue >= 1000000000) { // Check for billions
        return `${sign}$${(absValue / 1000000000).toFixed(2)}B`;
    } else if (absValue >= 1000000) { // Check for millions
        return `${sign}$${(absValue / 1000000).toFixed(2)}M`;
    } else if (absValue >= 100000) { // Check for hundreds of thousands
        return `${sign}$${(absValue / 1000).toFixed(0)}k`;
    } else if (absValue >= 1000) { // Check for thousands
        return `${sign}$${(absValue / 1000).toFixed(2)}k`;
    } else {
        return new Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0 
        }).format(value);
    }
};


