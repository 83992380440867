import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDollar, formatDollarShort } from './utilityFunctions';

// Styles for the component
const useStyles = makeStyles((theme) => ({
    fieldValue: {
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      height: '100%',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  }));
  
  const FinancialSummaryComponent = (projectData) => {
    const classes = useStyles();
    const project = projectData?.projectData || projectData;
    const contractData = project?.owner_contact_summary
    console.log (contractData)
    const comparisonData = project?.compared_to_penultimate_snapshot;
    console.log(comparisonData)

    // Calculate Profit Value
    const currentProfitValue = (typeof contractData?.current_profit_percentage === 'number' && typeof contractData?.revised_contract_value === 'number')
    ? (contractData.current_profit_percentage / 100) * contractData.revised_contract_value
    : null;

    // Calculate Profit Percentage Change


    // Calc Owner Contract Value Change
    const ownerContractChangeValue = contractData?.revised_contract_value - contractData?.original_contract_value;

    // Contract Data
    const cleanedContractData = {
      revisedOwnerContract: formatDollar(contractData?.revised_contract_value) || '--',
      profitValue: '$100,000',
      profitPercentage: typeof contractData?.current_profit_percentage === 'number' 
      ? contractData.current_profit_percentage.toFixed(2)
      : '--',
      ownerContractChange: typeof ownerContractChangeValue === 'number' && !isNaN(ownerContractChangeValue)
        ? formatDollarShort(ownerContractChangeValue)
        : '--',
      profitPercentageChange: '+25',
      jtdCosts: typeof contractData?.jtd_costs === 'number'
      ? contractData.jtd_costs : '--',
    };

    const cleanedComparisonData = {
      profitPercentageChangeByMonth: typeof comparisonData?.current_profit_percentage_change === 'number' ? comparisonData.current_profit_percentage_change.toFixed(2) : '--',
      amountChangeRevisedContract: typeof comparisonData?.revised_contract_value_change === 'number' ? comparisonData.revised_contract_value_change.toFixed(2) : '--',
      costsChangeAmount: typeof comparisonData?.total_estimated_costs_change === 'number' ? comparisonData.total_estimated_costs_change : '--',

    }
  
    return (
      <CardContent style={{ height: '100%' }}>
        <Grid container spacing={3} className={classes.gridContainer}>
          {/* Column 1 */}
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div>
              <Typography className="card-title-financials">Owner Contract</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
              <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                <div>{cleanedContractData.revisedOwnerContract}</div>
                <div style={{fontSize: 'small', marginTop: '-5px'}}>({formatDollar(cleanedComparisonData.amountChangeRevisedContract)})</div>
                </span>
              </Typography>
            </div>
            <div>
              <Typography className="card-title-financials">JTD Costs</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                
                <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                  <div>{formatDollar(cleanedContractData.jtdCosts)}</div>
                  <div style={{fontSize: 'small', marginTop: '-5px'}}>({formatDollar(cleanedComparisonData.costsChangeAmount)})</div>
                </span>
              </Typography>
            </div>
          </Grid>
  
          {/* Column 2 */}
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div>
              <Typography className="card-title-financials">Profit Value</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                {formatDollar(currentProfitValue)}
              </Typography>
            </div>
            <div>
              <Typography className="card-title-financials">Current Profit %</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                  <div>{cleanedContractData.profitPercentage}%</div>
                  <div style={{fontSize: 'small', marginTop: '-5px'}}>({cleanedComparisonData.profitPercentageChangeByMonth}%)</div>
                </span>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    );
  };
  
  export default FinancialSummaryComponent;