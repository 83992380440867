import React, { useEffect } from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const TotalEstimatedCosts = ({ budgetData }) => {
    
    const getTotalEstimatedCosts = () => {
        if (budgetData && budgetData.owner_contact_summary && budgetData.owner_contact_summary?.total_estimated_costs) {
            const totalCosts = budgetData.owner_contact_summary.total_estimated_costs
            const roundedTotalCosts = Math.ceil(totalCosts);
            return `$${roundedTotalCosts.toLocaleString()}`;
        }
        return '--';
    };

return (
    <div>
        <Typography style={{ textAlign: 'center' }} variant="body1" className='main-key-project-info-body'>
            {getTotalEstimatedCosts()}
        </Typography>
    </div>
    );
}

export default TotalEstimatedCosts;